import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import MemberIcon from '../../../shared/icons/project/MemberIcon';
import EpisodeListIcon from '../../../shared/icons/project/EpisodeListIcon';

import {storyCoverImageUrl} from '../../../../helpers/images';
import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../styles/variables';

import Project from '../../../../../domain/entities/Project';

interface Props {
  project: Project;
}

const ProjectListItem: React.FC<Props> = props => {
  const {project} = props;
  const {story} = project;
  const linkTo = useLinkTo();
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(project.story),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const genre = story.genres[0];
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${project.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.left}>
        {story.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
      </View>
      <View style={styles.right}>
        <Text style={styles.title}>{story.title}</Text>
        <Text style={styles.leader}>
          リーダー：{project.story.writerUser.penName}
        </Text>
        <Text style={styles.catchPhrase}>{story.catchPhrase}</Text>
        <Text style={styles.genre}>{story.genres[0].name}</Text>
        <View style={styles.info}>
          <View style={styles.infoMemberCount}>
            <MemberIcon />
            <Text style={styles.infoMemberTextLeft}>
              {project.projectMembersCount}
            </Text>
            <Text style={styles.infoMemberTextRight}>人</Text>
          </View>
          <View style={styles.infoEpisodeCount}>
            <EpisodeListIcon />
            <Text style={styles.infoEpisodeTextLeft}>エピソード</Text>
            <Text style={styles.infoEpisodeTextRight}>
              {story.deliveredEpisodesCount}
            </Text>
          </View>
        </View>
        <View style={styles.labels}>
          <View style={styles.label}>
            <Text style={styles.labelText}>
              {project.participationMethod === 'free_participation'
                ? '誰でも参加'
                : '承認制'}
            </Text>
          </View>
          <View style={styles.label}>
            <Text style={styles.labelText}>
              {story.format === 'format_series' ? 'シリーズ' : 'オムニバス'}
            </Text>
          </View>
        </View>
        <Text style={styles.updatedAt}>
          最終更新：{dateformat(project.updatedAt, 'yyyy.mm.dd')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(ProjectListItem);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    margin: 10,
    flexDirection: 'row',
  } as ViewStyle,
  left: {} as ViewStyle,
  right: {
    marginLeft: 10,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  leader: {
    color: '#222',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  catchPhrase: {
    color: '#666',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  genre: {
    color: '#666',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  info: {
    flexDirection: 'row',
    marginTop: 4,
  } as ViewStyle,
  infoMemberCount: {
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  infoMemberTextLeft: {
    color: '#999',
    fontSize: 10,
    marginLeft: 2,
    marginRight: 1,
  } as TextStyle,
  infoMemberTextRight: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  infoEpisodeCount: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 8,
  } as ViewStyle,
  infoEpisodeTextLeft: {
    color: '#999',
    fontSize: 10,
    marginLeft: 3,
    marginRight: 3,
  } as TextStyle,
  infoEpisodeTextRight: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  labels: {
    marginTop: 4,
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    borderWidth: 1,
    borderColor: '#666',
    paddingVertical: 1,
    paddingHorizontal: 2,
    width: 'fit-content' as any,
    marginRight: 4,
  } as ViewStyle,
  labelText: {
    color: '#666',
    fontSize: 8,
    fontWeight: 'bold',
  } as TextStyle,
  updatedAt: {
    color: '#666',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
});
