import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import FastImageAdapter from '../../shared/fast_image/FastImageAdapter';
import ActionModal from '../../shared/modals/ActionModal';

import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import {storyCoverImageUrl} from '../../../helpers/images';
import StoryCoverImageDecorator from '../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../styles/variables';

import Project from '../../../../domain/entities/Project';
import ProjectUserStatus from '../../../../domain/entities/ProjectUserStatus';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  project: Project;
  wrriterUserId: number;
  onForceUpdate: () => void;
}

const ProjectListItem: React.FC<Props> = props => {
  const {project, wrriterUserId, onForceUpdate} = props;
  const {story} = project;
  const linkTo = useLinkTo();
  const {showActionSheetWithOptions} = useActionSheet();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(project.story),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${project.id}`);
  }, []);
  const onCheckLeaderAndOpenActionSheet = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      `/api/writer/project_user_status`,
      {
        projectId: project.id,
      },
    ).then(result => {
      onOpenActionSheet(result.body.role === 'leader');
    });
  }, []);
  const onOpenActionSheet = React.useCallback((isLeader: boolean) => {
    const options = [
      ...(isLeader ? ['プロジェクトを編集する'] : []),
      'プロジェクトを抜ける',
      'キャンセル',
    ];
    const editProjectIndex = 0;
    const leaveProjectIndex = isLeader ? 1 : 0;
    const cancelButtonIndex = isLeader ? 2 : 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        options,
        destructiveButtonIndex: leaveProjectIndex,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case leaveProjectIndex:
            setVisibleModal(true);
            break;
          case editProjectIndex:
            linkTo(`/projects/${project.id}/edit`);
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);

  const onRequestClose = React.useCallback(() => {
    setVisibleModal(false);
  }, []);
  const onAccept = React.useCallback(() => {
    TapNovelTypedRestApi.delete(
      `/api/writer/user/project_members/${project.id}`,
    ).then(() => {
      setVisibleModal(false);
      onForceUpdate();
    });
  }, []);
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <View style={styles.left}>
          {story.hasCoverImage ? (
            <FastImageAdapter style={styles.image} source={source} />
          ) : (
            <View style={styles.image}>
              <Text style={styles.noImageText}>表紙なし</Text>
            </View>
          )}
        </View>
        <View style={styles.right}>
          <Text style={styles.title}>{story.title}</Text>
          <Text style={styles.catchPhrase}>{project.story.catchPhrase}</Text>
          {wrriterUserId === project.story.writerUser.id ? (
            <View style={styles.leader}>
              <Text style={styles.leaderText}>リーダー</Text>
            </View>
          ) : null}
          <Text style={styles.updatedAt}>2022.04.27 更新</Text>
        </View>
        <TouchableOpacity
          style={styles.headerRight}
          onPress={onCheckLeaderAndOpenActionSheet}>
          <View style={styles.more}>
            <MoreIcon />
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
      <ActionModal
        visible={visibleModal}
        title={'プロジェクトを抜ける'}
        description={
          '一度プロジェクトを抜けると再度参加することができなくなる可能性がございます。\nよろしいですか？'
        }
        titleStyle={{color: '#222'}}
        descriptionStyle={{textAlign: 'center', fontSize: 14}}
        withCloseIcon={true}
        buttonText={'抜ける'}
        onRequestClose={onRequestClose}
        onAccept={onAccept}
      />
    </>
  );
};

export default React.memo(ProjectListItem);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 10,
    flexDirection: 'row',
  } as ViewStyle,
  left: {} as ViewStyle,
  right: {
    marginLeft: 10,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  catchPhrase: {
    color: '#666',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  leader: {
    backgroundColor: '#ff8f13',
    width: 'fit-content' as any,
    paddingVertical: 1,
    paddingHorizontal: 2,
  } as ViewStyle,
  leaderText: {
    color: '#fff',
    fontSize: 8,
    fontWeight: 'bold',
  } as TextStyle,
  updatedAt: {
    color: '#666',
    fontSize: 10,
    marginTop: 16,
  } as TextStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
    zIndex: 100,
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
