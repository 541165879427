import React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {getDoubleResizeImageUrl} from '../../../../helpers/images';

import ActorCharacterFace from '../../../../../domain/entities/shop/ActorCharacterFace';

import {scaledValue} from '../../../../styles';

interface Props {
  actorCharacterFace: ActorCharacterFace;
  width?: number;
  active?: boolean;
  onSelectActorCharacterFace: (actorCharacterFace: ActorCharacterFace) => void;
}

const ActorCharacterFaceGridListItem: React.FC<Props> = props => {
  const {actorCharacterFace, width, active, onSelectActorCharacterFace} = props;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(actorCharacterFace, {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      }),
    }),
    [],
  );
  const containerStyle = React.useMemo((): StyleProp<ViewStyle> | undefined => {
    return [
      styles.container,
      {
        marginVertical: scaledValue(width, 4),
        marginHorizontal: scaledValue(width, 2),
      },
    ];
  }, [width]);
  const imageWrapperStyle = React.useMemo(():
    | StyleProp<ViewStyle>
    | undefined => {
    return [
      styles.imageWrapper,
      {
        width: scaledValue(width, 112),
        height: scaledValue(width, 109),
        borderColor: active ? '#f5c71c' : 'white',
      },
    ];
  }, [width, active]);
  const onPress = React.useCallback(() => {
    onSelectActorCharacterFace(actorCharacterFace);
  }, []);
  return (
    <Pressable style={containerStyle} onPress={onPress}>
      <View style={imageWrapperStyle}>
        <Image
          source={source}
          style={[
            styles.image,
            getPositionForCenteringFace(actorCharacterFace, {left: -0.5}),
          ]}
        />
      </View>
      <Text style={styles.label}>{actorCharacterFace.faceName}</Text>
    </Pressable>
  );
};

export default React.memo(ActorCharacterFaceGridListItem);

const IMAGE_WIDTH = 450;
const IMAGE_HEIGHT = Math.round((IMAGE_WIDTH * 2039) / 2894);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  imageWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
  label: {
    color: '#666',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 4,
  } as TextStyle,
});

const getPositionForCenteringFace = (
  actorCharacterFace: ActorCharacterFace,
  offset: {top?: number; left?: number} = {},
) => {
  const offsetTop = offset.top || 0;
  const offsetLeft = offset.left || 0;
  if (!actorCharacterFace.faceCenterPosition) {
    return {};
  }
  return {
    top: `${
      -(actorCharacterFace.faceCenterPosition.top - 0.15 + offsetTop) * 100
    }%`,
    left: `${
      -(actorCharacterFace.faceCenterPosition.left + offsetLeft) * 100
    }%`,
  } as ImageStyle;
};
