import {applyMiddleware, createStore, Middleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from '../reducers';

const middleware: Middleware[] = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger() as Middleware);
}

export default createStore(reducer, applyMiddleware(...middleware));
