import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Episode from '../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';
import LimitEpisodePublicationCreateEvent from '../../../domain/services/analytics_particular_events/LimitEpisodePublicationCreateEvent';

export interface Params {
  title?: string | null;
  catchPhrase?: string | null;
  summary?: string | null;
  introduction?: string | null;
  imageUrl?: string | null;
  genreIds?: number[] | null;
  visibility?: 'open_to_all' | 'members_only' | 'paid' | 'subscription';
  published?: boolean;
  scheduledDeliveredAt?: Date;
  rowOrderPosition?: number | null;
  ignoreUpdate?: boolean;
  writerUserEpisodeCommentAttributes?: {
    id?: number;
    comment: string;
    commentForSupporter: string;
  };
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Episode> {
  return async (dispatch: Dispatch<any>): Promise<Episode> => {
    try {
      const payload = await new NetEpisodesRepository().update(id, {
        episode: params,
      });
      if (!params.ignoreUpdate) {
        dispatch({payload, type: Types.UpdateEpisodeSuccess});
      }
      new LimitEpisodePublicationCreateEvent().create(payload);
      sendDefaultCustomEvent({
        resourceType: 'episode',
        resourceId: payload.id,
        resourceName: payload.title,
        actionName: 'update',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateEpisodeFailure});
      throw err;
    }
  };
}
