import * as React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {actorCharacterFaceImageUrl} from '../../../../../../helpers/images';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';

interface Props {
  style?: StyleProp<ViewStyle>;
  actorCharacterFace: ActorCharacterFace;
  width: number;
  inverted?: boolean;
  selected: boolean;
  onSelectActorCharacterFace: (actorCharacterFace: ActorCharacterFace) => void;
}

const ActorCharacterFaceListItem: React.FunctionComponent<Props> = props => {
  const {
    style,
    actorCharacterFace,
    width,
    inverted,
    selected,
    onSelectActorCharacterFace,
  } = props;
  const imageSize = width * IMAGE_ZOOM;
  const position = actorCharacterFace.getPositionForCenteringFace({
    top: 0.05,
    left: 0.25,
  });
  const uri = actorCharacterFaceImageUrl(actorCharacterFace, 'large');
  return (
    <Pressable onPress={() => onSelectActorCharacterFace(actorCharacterFace)}>
      <View style={[style, {width, height: width + LABEL_HEIGHT}]}>
        <View style={{width, height: width}}>
          <View
            style={[
              styles.imageWrapper,
              selected ? styles.imageWrapperActive : null,
              {width, height: width, position: 'absolute'},
            ]}>
            <Image
              style={[
                {width: imageSize, height: imageSize, ...(position as any)},
                inverted ? styles.inverted : null,
              ]}
              resizeMode={'contain'}
              source={{uri}}
            />
          </View>
        </View>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>{actorCharacterFace.faceName}</Text>
        </View>
      </View>
    </Pressable>
  );
};

const LABEL_HEIGHT = 20;

const IMAGE_ZOOM = 2.5;

export default React.memo(ActorCharacterFaceListItem);

const styles = StyleSheet.create({
  imageWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    borderColor: 'transparent',
    overflow: 'hidden',
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  labelWrapper: {
    marginVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    color: '#666666',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
});
