import * as React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {actorCharacterImageUrl} from '../../../../../../helpers/images';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';

interface Entity {
  id: number;
  actorCharacter: ActorCharacter;
  getLabel: () => string;
}

interface Props<E extends Entity> {
  entity: E;
  selected: boolean;
  width: number;
  clipped: boolean;
  inverted?: boolean;
  onSelectEntity: (entity: E) => void;
}

export default class ActorCharacterFaceList<
  E extends Entity,
> extends React.PureComponent<Props<E>> {
  public render(): React.ReactNode {
    const {entity, selected, clipped, width, inverted} = this.props;
    const imageWrapperHeight = width / (clipped ? 1 : ASPECT_RATIO);
    const zoom = clipped ? IMAGE_ZOOM : 1;
    const uri = actorCharacterImageUrl(entity.actorCharacter, 'middle');
    const position = clipped
      ? entity.actorCharacter.getPositionForCenteringFace({
          top: 0.05,
          left: 0.25,
        })
      : {};
    return (
      <Pressable onPress={this.handleSelectActorCharacter}>
        <View
          style={[
            styles.container,
            {width, height: imageWrapperHeight + LABEL_HEIGHT},
          ]}>
          <View style={{width, height: imageWrapperHeight}}>
            <View
              style={[
                styles.imageWrapper,
                selected ? styles.imageWrapperActive : null,
                {width, height: imageWrapperHeight, position: 'absolute'},
              ]}>
              <Image
                style={[
                  {
                    width: width * zoom,
                    height: imageWrapperHeight * zoom,
                    ...(position as any),
                  },
                  inverted ? styles.inverted : null,
                ]}
                resizeMode={'contain'}
                source={{uri}}
              />
            </View>
          </View>
          <View style={styles.labelWrapper}>
            <Text style={styles.label}>{entity.getLabel() || 'なし'}</Text>
          </View>
        </View>
      </Pressable>
    );
  }

  private handleSelectActorCharacter = () => {
    const {entity, onSelectEntity} = this.props;
    onSelectEntity(entity);
  };
}

const ASPECT_RATIO = 0.7;

const LABEL_HEIGHT = 20;

const IMAGE_ZOOM = 2.5;

const styles = StyleSheet.create({
  container: {
    margin: 2,
  } as ViewStyle,
  imageWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    borderColor: 'transparent',
    overflow: 'hidden',
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  labelWrapper: {
    marginVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {} as TextStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
});
