import * as React from 'react';
import {
  Image,
  ImageProps,
  ImageSourcePropType,
  StyleSheet,
  Platform,
} from 'react-native';

import CachingImage from './CachingImage';

interface Props extends ImageProps {}

interface State {
  source?: ImageSourcePropType;
}

export default class NoFlickerImage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      source: props.source,
    };
  }

  public render() {
    if (Platform.OS === 'ios') {
      return <Image {...this.props} />;
    }
    return (
      <>
        <CachingImage
          {...this.props}
          style={styles.hide}
          onSourceLoaded={this.onSourceLoaded}
        />
        <Image {...this.props} source={this.state.source} />
      </>
    );
  }

  private onSourceLoaded = () => {
    if (this.state.source !== this.props.source) {
      this.setState({
        source: this.props.source,
      });
    }
  };
}

const styles = StyleSheet.create({
  hide: {
    display: 'none',
  },
});
