import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  value?: boolean;
  onValueChange?: (e: any) => void;
}

const Switch: React.FC<Props> = props => {
  const {value = false, onValueChange} = props;
  const thumbRef = React.useRef(null);
  const trackCurrentColor = value === true ? '#ff8f13' : '#e5e5e5';
  const rootStyle = [styles.root, {height, width}];
  const trackStyle = [
    styles.track,
    {backgroundColor: trackCurrentColor, borderRadius: 22},
  ];
  const onChange = React.useCallback(
    (event: any) => {
      if (onValueChange != null) {
        onValueChange(event.nativeEvent.target.checked);
      }
    },
    [value],
  );
  const thumbStyle = [
    styles.thumb,
    value && styles.thumbActive,
    {
      backgroundColor: thumbCurrentColor,
      height: thumbHeight - 2,
      marginStart: 2,
      width: thumbWidth - 2,
    },
  ];
  return (
    <View style={rootStyle}>
      <View style={trackStyle} />
      <View ref={thumbRef} style={thumbStyle} />
      <input
        type={'checkbox'}
        role={'switch'}
        checked={value}
        onChange={onChange}
        style={
          StyleSheet.flatten([
            styles.nativeControl,
            styles.cursorInherit,
          ]) as any
        }
      />
    </View>
  );
};

export default React.memo(Switch);

const height = 26;
const width = 44;
const thumbCurrentColor = '#fff';
const thumbHeight = height;
const thumbWidth = thumbHeight;

const styles = StyleSheet.create({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    justifyContent: 'center',
  } as ViewStyle,
  cursorDefault: {
    cursor: 'default' as any,
  } as ViewStyle,
  cursorInherit: {
    cursor: 'inherit' as any,
  } as ViewStyle,
  track: {
    forcedColorAdjust: 'none',
    ...StyleSheet.absoluteFillObject,
    height: '100%',
    margin: 'auto',
    transitionDuration: '0.1s',
    width: '100%',
  } as ViewStyle,
  thumb: {
    forcedColorAdjust: 'none',
    alignSelf: 'flex-start',
    boxShadow: '0px 1px 3px rgba(0,0,0,0.5)',
    start: '0%',
    transitionDuration: '0.1s',
    ...({
      borderRadius: '100%',
      transform: 'translateZ(0)',
    } as any),
  } as ViewStyle,
  thumbActive: {
    insetInlineStart: '36%',
  } as ViewStyle,
  nativeControl: {
    ...StyleSheet.absoluteFillObject,
    height: '100%',
    margin: 0,
    appearance: 'none',
    padding: 0,
    width: '100%',
  } as ViewStyle,
});
