import EntityMapper from './EntityMapper';

import Episode from '../../../domain/entities/Episode';

import UserMapper, {UserData} from './writer/UserMapper';
import UserEpisodeCommentMapper, {
  UserEpisodeCommentData,
} from './writer/UserEpisodeCommentMapper';

export interface EpisodeData {
  id: number;
  story_id: number;
  title: string;
  has_image: boolean;
  original_image_url: string;
  published: boolean;
  visibility: 'open_to_all' | 'members_only' | 'paid' | 'subscription';
  updated_at: string;
  created_at: string;
  tap_count: number;
  tap_count_without_clear: number;
  total_speech_text_length: number;
  total_descriptive_text_length: number;
  scheduled_delivered_at: string | null;
  with_sound: boolean;
  with_voice: boolean;
  scenes_count?: number;
  writer_user?: UserData;
  writer_user_episode_comment?: UserEpisodeCommentData;
  estimated_total_tap_count?: number;
  reactions_count: number | null;
  approximate_reading_time_text?: string | null;
}

export default class EpisodeMapper
  implements EntityMapper<EpisodeData, Episode>
{
  private userMapper: UserMapper;
  private userEpisodeCommentMapper: UserEpisodeCommentMapper;

  constructor() {
    this.userMapper = new UserMapper();
    this.userEpisodeCommentMapper = new UserEpisodeCommentMapper();
  }

  public map(obj: EpisodeData): Episode {
    return new Episode(
      obj.id,
      obj.story_id,
      obj.title,
      obj.has_image,
      obj.original_image_url,
      obj.visibility,
      obj.published,
      obj.scheduled_delivered_at ? new Date(obj.scheduled_delivered_at) : null,
      new Date(obj.updated_at),
      new Date(obj.created_at),
      obj.tap_count,
      obj.tap_count_without_clear,
      obj.total_speech_text_length,
      obj.total_descriptive_text_length,
      obj.with_sound,
      obj.with_voice,
      obj.scenes_count === undefined ? null : obj.scenes_count,
      obj.writer_user_episode_comment
        ? this.userEpisodeCommentMapper.map(obj.writer_user_episode_comment)
        : null,
      obj.estimated_total_tap_count,
      obj.approximate_reading_time_text,
      obj.reactions_count,
      obj.writer_user ? this.userMapper.map(obj.writer_user) : null,
    );
  }
}
