import * as React from 'react';
import {StyleProp, TextStyle} from 'react-native';

import {createIconSetFromIcoMoon} from 'react-native-vector-icons';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const customFontIcon = require('../../../../assets/fonts/selection.json');
const IcoMoon = createIconSetFromIcoMoon(customFontIcon);

interface Props {
  name: string;
  style?: StyleProp<TextStyle> | any;
  size?: number;
  color?: string;
}

const Icon: React.FunctionComponent<Props> = props => {
  const {name, style} = props;
  const size = props.size || 15;
  const color = props.color || 'black';
  return <IcoMoon name={name} style={style} size={size} color={color} />;
};

export default React.memo(Icon);
