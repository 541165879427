import Episode from '../../entities/Episode';

import NetAnalyticsParticularEventsRepository from '../../../data/repositories/writer/NetAnalyticsParticularEventsRepository';

import {sendDefaultCustomEvent} from '../../../presentation/helpers/analytics';

export default class LimitEpisodePublicationCreateEvent {
  public async create(episode: Episode) {
    if (!episode.published || episode.visibility === 'open_to_all') {
      return;
    }
    try {
      sendDefaultCustomEvent({
        resourceType: 'limit_episode_publication',
        resourceId: episode.id,
        actionName: episode.published ? 'create' : 'destroy',
      });
      const analyticsParticularEvent =
        await new NetAnalyticsParticularEventsRepository().create({
          analyticsParticularEventParams: {
            resourceType: 'limit_episode_publication',
            actionName: 'create',
            resourceId: episode.id,
          },
        });
      switch (analyticsParticularEvent.sameEventsCount) {
        case 1:
          sendDefaultCustomEvent({
            resourceType: 'first_limit_episode_publication',
            resourceId: analyticsParticularEvent.userId,
            actionName: 'create',
          });
          break;
        case 2:
          sendDefaultCustomEvent({
            resourceType: 'second_limit_episode_publication',
            resourceId: analyticsParticularEvent.userId,
            actionName: 'create',
          });
          break;
      }
      return analyticsParticularEvent.sameEventsCount;
    } catch (e) {
      return null;
    }
  }
}
