import {camelizeKeys} from 'humps';

import GenderMapper, {GenderData} from '../GenderMapper';
import NameLabelColor from '../../../../domain/value_objects/NameLabelColor';

import CharacterResourceOptions from '../../../../domain/value_objects/resource_options/CharacterResourceOptions';

export interface CharacterResourceOptionsData {
  container_style?: {[key: string]: string};
  wide_character_style?: {[key: string]: string};
  gender?: GenderData;
  transparent?: boolean;
  name_label_color?: NameLabelColor;
  inverted?: boolean;
}

export default class CharacterResourceOptionsMapper {
  private genderMapper = new GenderMapper();

  public map(obj: CharacterResourceOptionsData): CharacterResourceOptions {
    return new CharacterResourceOptions(
      obj.container_style ? camelizeKeys(obj.container_style) : undefined,
      obj.wide_character_style
        ? camelizeKeys(obj.wide_character_style)
        : undefined,
      obj.gender ? this.genderMapper.map(obj.gender) : undefined,
      obj.transparent,
      obj.name_label_color,
      obj.inverted,
    );
  }
}
