import {isAndroid} from '../../../../data/data_stores/net/UserAgent';
import {isSafari} from '../../../../data/data_stores/net/UserAgent';

export const filetypes = {
  audio: {
    acceptWeb: isSafari
      ? 'audio/*,video/*,.wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3'
      : 'audio/*',
    pattern: /^(audio|video)\/(mpeg|x-mpeg|aac|x-m4a|m4a|wav|x-wav|mp4)/,
    errorMessage: 'VoiceはMP3/AAC/WAVEでアップロードしてください',
    maxFileMBSize: 5,
  },
  image: {
    acceptWeb: isAndroid
      ? 'image/png,image/jpg,image/jpeg,video/*'
      : 'image/png,image/jpg,image/jpeg',
    pattern: /^image\/(png|jpg|jpeg)/,
    errorMessage: '画像はPNGかJPEGでアップロードしてください',
    maxFileMBSize: 10,
  },
  any: {
    acceptWeb: '',
    pattern: /.*/,
    errorMessage: '',
    maxFileMBSize: 10,
  },
};
