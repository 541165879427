import Episode from '../entities/Episode';

export default class EpisodeForm {
  public id: number | null = null;
  public storyId: number | null = null;
  public title = '';
  public published?: 'public' | 'private';
  public visibility: 'open_to_all' | 'members_only' | 'paid' | 'subscription' =
    'members_only';
  public writerUserEpisodeCommentId: number | null = null;
  public writerUserEpisodeCommentComment = '';
  public writerUserEpisodeCommentCommentForSupporter = '';

  public bind(episode: Episode) {
    this.id = episode.id;
    this.storyId = episode.storyId;
    this.title = episode.title;
    this.published = episode.published ? 'public' : 'private';
    this.visibility = episode.visibility;
    this.writerUserEpisodeCommentId = episode.writerUserEpisodeComment
      ? episode.writerUserEpisodeComment.id
      : null;
    this.writerUserEpisodeCommentComment = episode.writerUserEpisodeComment
      ? episode.writerUserEpisodeComment.comment
      : '';
    this.writerUserEpisodeCommentCommentForSupporter =
      episode.writerUserEpisodeComment
        ? episode.writerUserEpisodeComment.commentForSupporter || ''
        : '';
  }

  public toParams(): {[key: string]: any} {
    const storyId = this.storyId;
    const title = this.title;
    const visibility = this.visibility;
    const writerUserEpisodeCommentAttributes = this.writerUserEpisodeCommentId
      ? {
          id: this.writerUserEpisodeCommentId,
          comment: this.writerUserEpisodeCommentComment,
          commentForSupporter: this.writerUserEpisodeCommentCommentForSupporter,
        }
      : this.writerUserEpisodeCommentComment.length > 0 ||
        this.writerUserEpisodeCommentCommentForSupporter.length > 0
      ? {
          comment: this.writerUserEpisodeCommentComment,
          commentForSupporter: this.writerUserEpisodeCommentCommentForSupporter,
        }
      : undefined;
    return {
      storyId,
      title,
      visibility,
      published:
        this.published === undefined ? undefined : this.published === 'public',
      writerUserEpisodeCommentAttributes,
    };
  }
}
