import * as React from 'react';
import {Image, ImageProps, ImageSourcePropType} from 'react-native';

const cachedSources = new Set<ImageSourcePropType>([]);

interface Props extends ImageProps {
  onSourceLoaded: () => void;
}

interface State {
  source: ImageSourcePropType;
}

export default class CachingImage extends React.PureComponent<Props, State> {
  public componentDidUpdate() {
    const {source, onSourceLoaded} = this.props;
    if (source && cachedSources.has(source)) {
      onSourceLoaded();
    }
  }

  public render() {
    return <Image {...this.props} onLoad={this.onSourceLoadedInCache} />;
  }

  private onSourceLoadedInCache = () => {
    const {source, onSourceLoaded} = this.props;
    if (source && !cachedSources.has(source)) {
      cachedSources.add(source);
      onSourceLoaded();
    }
  };
}
