import * as React from 'react';
import {Animated, LayoutChangeEvent, View, StyleSheet} from 'react-native';
//import SwipeableViews from 'react-swipeable-views';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SwipeableViews = require('react-swipeable-views').default;

import DefaultTabBar from './DefaultTabBar';

import ScrollableTabBar, {
  Props as ScrollableTabBarProps,
} from './ScrollableTabBar';

export interface ChangeTabProperties {
  i: number;
  ref?: JSX.Element;
  from: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type TabBarProps<T = {}> = T & {
  goToPage?: (pageNumber: number) => void;
  tabs?: JSX.Element[];
  activeTab?: number;
  scrollValue?: Animated.Value;
  containerWidth?: number;
};

interface Props extends ScrollableTabBarProps {
  index?: number;
  initialPage?: number;
  locked?: boolean;
  renderTabBar?: (props?: TabBarProps) => JSX.Element;
  onChangeTab?(value: ChangeTabProperties): void;
  onTransitionStartForWeb?: () => void;
  onTransitionEndForWeb?: () => void;
  [key: string]: any;
}

interface State {
  currentPage: number;
  contentHeight?: number;
}

class ScrollableTabView extends React.Component<Props, State> {
  private switching = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: props.initialPage || 0,
    };
  }

  public render(): React.ReactNode {
    const tabs = [] as JSX.Element[];
    React.Children.map(this.props.children as any, child => {
      tabs.push(child.props.tabLabel);
    });
    const tabBarProps = {
      goToPage: this.goToPage,
      tabs,
      activeTab: this.state.currentPage,
    };
    return (
      <View style={styles.container}>
        {this.renderScrollableTab(tabBarProps)}
        <View style={styles.content} onLayout={this.handleLayout}>
          {this.renderContent()}
        </View>
      </View>
    );
  }

  public goToPage = (page: number) => {
    const {onChangeTab} = this.props;
    if (onChangeTab) {
      setTimeout(() => {
        onChangeTab({i: page, from: this.state.currentPage});
      }, 200);
    }
    this.setState({
      currentPage: page,
    });
  };

  private renderContent = () => {
    const {locked} = this.props;
    const {contentHeight} = this.state;
    return (
      <SwipeableViews
        index={this.state.currentPage}
        disabled={locked}
        enableMouseEvents={true}
        onChangeIndex={this.goToPage}
        onSwitching={this.handleSwitching}
        onTransitionEnd={this.handleTransitionEnd}>
        {React.Children.map(this.props.children, child => {
          return <View style={{height: contentHeight}}>{child}</View>;
        })}
      </SwipeableViews>
    );
  };

  private handleSwitching = () => {
    const {onTransitionStartForWeb} = this.props;
    if (this.switching || !onTransitionStartForWeb) {
      return;
    }
    this.switching = true;
    onTransitionStartForWeb();
  };

  private handleTransitionEnd = () => {
    const {onTransitionEndForWeb} = this.props;
    this.switching = false;
    onTransitionEndForWeb && onTransitionEndForWeb();
  };

  private renderScrollableTab = (props: any) => {
    if (this.props.renderTabBar) {
      return React.cloneElement(this.props.renderTabBar(), props);
    }
    return <ScrollableTabBar {...props} />;
  };

  private handleLayout = (e: LayoutChangeEvent) => {
    if (!e.nativeEvent.layout.height) {
      return;
    }
    this.setState({contentHeight: e.nativeEvent.layout.height});
  };
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
});

export default ScrollableTabView;
export {DefaultTabBar, ScrollableTabBar};
