import Entity from './Entity';

import WriterUser from './writer/User';
import WriterUserEpisodeComment from './writer/UserEpisodeComment';

export default class Episode implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public title: string,
    public hasImage: boolean,
    public originalImageUrl: string,
    public visibility: 'open_to_all' | 'members_only' | 'paid' | 'subscription',
    public published: boolean,
    public scheduledDeliveredAt: Date | null,
    public updatedAt: Date,
    public createdAt: Date,
    public tapCount: number,
    public tapCountWithoutClear: number,
    public totalSpeechTextLength: number,
    public totalDescriptiveTextLength: number,
    public withSound: boolean,
    public withVoice: boolean,
    public scenesCount: number | null = null,
    public writerUserEpisodeComment: WriterUserEpisodeComment | null = null,
    public estimatedTotalTapCount: number | null = null,
    public approximateReadingTimeText: string | null = null,
    public reactionsCount: number | null = null,
    public writerUser: WriterUser | null = null,
  ) {}

  public publishedDelivery(): boolean {
    if (!this.published) {
      return false;
    }
    return !this.scheduledDelivery();
  }

  public scheduledDelivery(): boolean {
    if (!this.scheduledDeliveredAt) {
      return false;
    }
    return new Date().getTime() < this.scheduledDeliveredAt.getTime();
  }

  public getTotalTextLength(): number {
    return this.totalSpeechTextLength + this.totalDescriptiveTextLength;
  }

  public getSpeechTextLengthRate(): number {
    return this.totalSpeechTextLength / this.getTotalTextLength();
  }
}
