import * as React from 'react';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';

import CharacterImageIcon from '../../shared/icons/CharacterImageIcon';

import {actorCharacterFaceImageUrl} from '../../../helpers/images';

import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';

interface Props {
  actorCharacterFace?: ActorCharacterFace | null;
  withIcon?: boolean;
  imageSize?: number;
  inverted?: boolean;
}

const CharacterImage: React.FunctionComponent<Props> = props => {
  const {actorCharacterFace, withIcon, imageSize, inverted} = props;
  const position = actorCharacterFace?.getPositionForCenteringFace();
  const size = imageSize || DEFAULT_IMAGE_SIZE;
  const imageWrapperStyle = {
    width: size,
    height: size,
    borderRadius: size / IMAGE_ZOOM,
  };
  const image = {
    width: size * IMAGE_ZOOM,
    height: size * IMAGE_ZOOM,
  };

  return actorCharacterFace ? (
    <>
      <View
        style={[
          styles.imageWrapper,
          imageWrapperStyle,
          inverted ? styles.inverted : null,
        ]}>
        <Image
          style={[image, position as any]}
          resizeMode={'contain'}
          source={{
            uri: actorCharacterFaceImageUrl(actorCharacterFace, 'large'),
            headers: {Accept: 'image/webp,image/apng,*/*'},
          }}
        />
      </View>
      {withIcon && (
        <View style={styles.imageIcon}>
          <CharacterImageIcon size={20} />
        </View>
      )}
    </>
  ) : (
    <View style={styles.noImage}>
      <CharacterImageIcon size={40} />
    </View>
  );
};

export default React.memo(CharacterImage);

const DEFAULT_IMAGE_SIZE = 112;
const IMAGE_ZOOM = 2;

const styles = StyleSheet.create({
  imageWrapper: {
    borderWidth: 1,
    borderColor: '#efefef',
    overflow: 'hidden',
    position: 'absolute',
  } as ViewStyle,
  imageIcon: {
    position: 'absolute',
    backgroundColor: '#efefef',
    right: 0,
    bottom: 0,
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  noImage: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  } as ViewStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});
