import * as React from 'react';
import {StyleSheet, TextStyle, Text, View, ViewStyle} from 'react-native';

import FormFieldComment from './FormFieldComment';
import FormFieldCommentForSupporter from './FormFieldCommentForSupporter';

import EditableLaterMessage from '../../shared/EditableLaterMessage';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Input from '../../shared/forms/Input';
import MultiSwitch from '../../shared/forms/MultiSwitch';
import LabelWithOption from '../../shared/forms/LabelWithOption';
import TooltipModal from '../../shared/modals/TooltipModal';
import AlertModal from '../../shared/modals/AlertModal';

import {formStyles} from '../../../styles/variables';

import EpisodeForm from '../../../../domain/forms/EpisodeForm';

interface Item {
  label: string;
  value: string;
}

interface Props {
  episodeForm: EpisodeForm;
  unlockedVisibility: boolean;
  disableVisibility?: boolean;
  allowedVideoSharing?: boolean;
  enabledPartnerProgram?: boolean;
  onSubmit?: (episodeForm: EpisodeForm, afterSubmit: () => void) => void;
}

interface State {
  title: string;
  published?: 'public' | 'private';
  visibility: 'open_to_all' | 'members_only' | 'paid' | 'subscription';
  writerUserEpisodeCommentComment: string;
  writerUserEpisodeCommentCommentForSupporter: string;
  visibleTooltipModal: boolean;
  alertMessage?: string;
}

export default class Form extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {episodeForm} = this.props;
    this.state = {
      visibility: episodeForm.visibility,
      title: episodeForm.title || '',
      writerUserEpisodeCommentComment:
        episodeForm.writerUserEpisodeCommentComment,
      writerUserEpisodeCommentCommentForSupporter:
        episodeForm.writerUserEpisodeCommentCommentForSupporter,
      visibleTooltipModal: false,
    };
  }

  public render(): React.ReactNode {
    const {unlockedVisibility, disableVisibility, enabledPartnerProgram} =
      this.props;
    const {
      title,
      visibility,
      writerUserEpisodeCommentComment,
      writerUserEpisodeCommentCommentForSupporter,
      visibleTooltipModal,
      alertMessage,
    } = this.state;
    return (
      <View style={styles.container}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={`エピソードタイトル（最大${MAX_TITLE_SIZE}文字）`}
            length={title.length}
            maxLength={MAX_TITLE_SIZE}
          />
          <Input
            style={this.validTitle() ? null : styles.inputError}
            value={title}
            onChangeText={this.handleChangeText}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'公開範囲'}
            onPressQuestion={this.openTooltipModal}
          />
          <MultiSwitch
            items={
              unlockedVisibility ? DEDICATED_VISIBILITY_ITEMS : VISIBILITY_ITEMS
            }
            value={visibility}
            disabled={disableVisibility}
            onSelectItem={this.handleSelectItemOption}
          />
        </View>
        <FormFieldComment
          comment={writerUserEpisodeCommentComment}
          onChangeComment={this.handleChangeComment}
        />
        {enabledPartnerProgram && this.state.visibility === 'members_only' && (
          <FormFieldCommentForSupporter
            comment={writerUserEpisodeCommentCommentForSupporter}
            onChangeComment={this.handleChangeCommentForSupporter}
          />
        )}
        <View style={styles.button}>
          <EditableLaterMessage />
          <PrimaryButton
            disabled={this.disabledButton()}
            onPress={this.handlePress}>
            決定
          </PrimaryButton>
        </View>
        <TooltipModal
          visible={visibleTooltipModal}
          title={'公開範囲'}
          description={
            <>
              <Text style={{marginBottom: 8, display: 'flex'}}>
                エピソードの閲覧権限を設定することができます。
              </Text>
              <Text style={{marginVertical: 8, display: 'flex'}}>
                「一般公開」を選択した場合は、このエピソードを全員が無料で閲覧することができるようになります。
                {'\n'}
                また、第三者による動画共有サービス設定がされている場合に共有対象となります。
              </Text>
              <Text style={{marginTop: 8, display: 'flex'}}>
                「会員限定」を選択した場合は、TapNovelの会員が無料チケットを使って閲覧することができるようになります。
              </Text>
            </>
          }
          onCloseModal={this.closeTooltipModal}
        />
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: undefined});
            }}>
            <View style={{flex: 1}}>
              <Text style={{textAlign: 'center'}}>{alertMessage}</Text>
            </View>
          </AlertModal>
        )}
      </View>
    );
  }

  private handlePress = () => {
    const {episodeForm, onSubmit} = this.props;
    const {
      title,
      visibility,
      writerUserEpisodeCommentComment,
      writerUserEpisodeCommentCommentForSupporter,
    } = this.state;
    if (!onSubmit) {
      return;
    }

    const newEpisodeForm = new EpisodeForm();
    newEpisodeForm.id = episodeForm.id;
    newEpisodeForm.storyId = episodeForm.storyId;
    newEpisodeForm.title = title;
    newEpisodeForm.visibility = visibility;
    newEpisodeForm.writerUserEpisodeCommentId =
      episodeForm.writerUserEpisodeCommentId;
    newEpisodeForm.writerUserEpisodeCommentComment =
      writerUserEpisodeCommentComment;
    newEpisodeForm.writerUserEpisodeCommentCommentForSupporter =
      writerUserEpisodeCommentCommentForSupporter;
    onSubmit(newEpisodeForm, () => {});
  };

  private handleChangeText = (title: string) => {
    this.setState({title});
  };

  private handleChangeComment = (writerUserEpisodeCommentComment: string) => {
    this.setState({writerUserEpisodeCommentComment});
  };

  private handleChangeCommentForSupporter = (
    writerUserEpisodeCommentCommentForSupporter: string,
  ) => {
    this.setState({writerUserEpisodeCommentCommentForSupporter});
  };

  private handleSelectItemOption = (item: Item) => {
    const {allowedVideoSharing} = this.props;
    if (
      item.value === 'open_to_all' ||
      item.value === 'members_only' ||
      item.value === 'paid' ||
      item.value === 'subscription'
    ) {
      if (allowedVideoSharing && item.value === 'open_to_all') {
        this.setState({
          alertMessage:
            '一般公開に設定するとこのエピソードの第三者による\n動画共有サービスでの投稿が許可されます。\n一度共有を許可したエピソードは、\n後から変更することはできなくなります。',
        });
      }
      this.setState({visibility: item.value});
    }
  };

  private disabledButton = (): boolean => {
    if (!this.validTitle()) {
      return true;
    }
    if (!this.validWriterUserEpisodeCommentComment()) {
      return true;
    }
    return false;
  };

  private validTitle = () => {
    const {title} = this.state;
    return title.length <= 40;
  };

  private validWriterUserEpisodeCommentComment = () => {
    const {
      writerUserEpisodeCommentComment,
      writerUserEpisodeCommentCommentForSupporter,
    } = this.state;
    return (
      writerUserEpisodeCommentComment.length <= 200 &&
      writerUserEpisodeCommentCommentForSupporter.length <= 200
    );
  };

  private openTooltipModal = () => {
    this.setState({visibleTooltipModal: true});
  };

  private closeTooltipModal = () => {
    this.setState({visibleTooltipModal: false});
  };
}

const DEDICATED_VISIBILITY_ITEMS = [
  {label: '一般公開', value: 'open_to_all'},
  {label: '会員限定', value: 'members_only'},
  {label: '有料会員限定', value: 'subscription'},
];

const VISIBILITY_ITEMS = [
  {label: '一般公開', value: 'open_to_all'},
  {label: '会員限定', value: 'members_only'},
];

const MAX_TITLE_SIZE = 40;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 20,
  } as ViewStyle,
  inputError: {
    color: '#f23406',
  } as TextStyle,
});
